import { AuthStore } from '../store/auth.store'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const { $authStore } = useNuxtApp()
    const authStore = $authStore as AuthStore
    const isLogged = computed(() => authStore.isLogged)

    if (!isLogged.value) {
        return navigateTo('/auth/login')
    }
    return
})
